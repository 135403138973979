import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/pages/login',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },

      // Inbound

      {
        path: '/apps/chatbot',
        name: 'Chatbot - your Laws companion',
        component: () => import('@/views/apps/saras/chatbot.vue'),
      },
      {
        path: '/apps/caselaws',
        name: 'CaseLaws',
        component: () => import('@/views/apps/saras/caselaws.vue'),
      },
      {
        path: '/apps/filessearch',
        name: 'File Search - Files are your buddy',
        component: () => import('@/views/apps/saras/files.vue'),
      },
      {
        path: '/apps/anomaly',
        name: 'Anomaly Detection',
        component: () => import('@/views/apps/saras/anomaly.vue'),
      },
      {
        path: '/apps/gstNotices',
        name: 'GST Notices Reconciliation',
        component: () => import('@/views/apps/saras/gstnotices.vue'),
      },

      /////////////////////////////////////////////////////////////////////////
      ////////////////////////////////////////////////////////////////////////

    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },

      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
